body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    src: url(./assets/fonts/metropolis/Metropolis-Regular.otf) format('otf');
  }

}