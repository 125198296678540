.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-metropolis-400 {
  font-family: "Metropolis", sans-serif;
  font-weight: 400;
}

.font-metropolis-700 {
  font-family: "Metropolis", sans-serif;
  font-weight: 700;
}

body {
  background: #f7f9fb;
  font-size: 16px;
  color: currentColor;
}

header {
  box-shadow: 0px 4px 8px -2px rgba(26, 149, 215, 0.1);
}

.placeholder-gray-500::placeholder {
  color: #a0aec0;
}

.border-gray-500 {
  border-color: #a0aec0;
}

.customShadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.error {
  color: #ff6b6b;
  font-size: 14px;
}

.text-red-500 {
  color: #ff6b6b;
}

.success {
  color: #1abc9c;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.markerData ul,
.markerData ol {
  margin-bottom: 10px;
  margin-left: 20px;
}

.markerData em {
  font-weight: 600;
}

.markerData p {
  margin-bottom: 10px;
}

.markerData p strong {
  color: #31b0f2;
}

.markerData ul li {
  margin-bottom: 10px;
  list-style: circle;
}

.markerData ol li {
  margin-bottom: 10px;
  list-style: decimal;
}

.markerData ol {
  margin-bottom: 10px;
}

.stepper-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.stepper-buttons button {
  margin: 5px;
  padding: 10px;
  text-align: center;
  z-index: 9;
  font-weight: 500;
}

.stepper-buttons div.count span {
  background-color: #fff;
  color: #66c4f5;
  border: 2px solid #66c4f5;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}

.stepper-buttons .current-step div.count span {
  background-color: #66c4f5;
  color: white;
  border: 2px solid #66c4f5;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}

.stepper-buttons button:disabled {
  cursor: default;
}

.stepper-line {
  width: 90%;
  height: 6px;
  background-color: #e0e0e0;
  position: relative;
  margin: auto;
  border-radius: 4px;
  margin-top: -70px;
}

.stepper-progress {
  height: 100%;
  background-color: #66c4f5;
  transition: width 0.3s ease;
  border-radius: 4px;
}

.stepper-content {
  margin-top: 20px;
}

.stepper-content form {
  margin-top: 10px;
}

.stepper-content label {
  display: block;
}

input:focus {
  background: none;
}

.border-red-500 {
  border-color: #ff6b6b;
}

/* .border-red-500 input{
  background: #ff6b6b18;
} */

.label {
  color: #94a3b8;
}

.tabActive {
  color: #31b0f2;
  border-color: #31b0f2;
}


select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background: url(./assets/images/arrow.svg) no-repeat right center;
}






@media only screen and (max-width: 991px) {
  .stepper-line {
    width: 88%;
  }
}

@media only screen and (max-width: 767px) {
  .stepper-line {
    width: 86%;
  }
}

@media only screen and (max-width: 640px) {
  .stepper-line {
    width: 76%;
  }
}
